<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <div class="mr-3">
        <h1>
          Redirecting to
          <div>
            {{ $route.query.url }}
          </div>
          please wait!
        </h1>
      </div>
      <div>
        <b-spinner
          style="width: 4rem; height: 4rem"
          class="mr-1"
          variant="primary"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  data() {
    return {
      res: null,
      isError: null,
      isLoading: true,
    };
  },
  created() {
    // this.checkUser();
  },
  mounted() {
    // http://localhost:8000/meeting/attendance/mark/4WOMl/vnvGW
    if (!this.$route.query.type) {
      // this.$router.push("/dashboard");
    }
    let data = {};

    let url =
      "/" +
      this.$route.query.type +
      "/attendance/mark/" +
      this.$cookies.get("userData").hashid +
      "/" +
      this.$route.query.hash_id +
      "?url=" +
      this.$route.query.url;
    data.url = url;

    this.$store
      .dispatch("app/get", data)
      .then((result) => {
        console.log(result);
        let join_url = result.data.join_url;
        if (join_url) {
          return (window.location.href = join_url);
        } else {
          return (window.location.href = this.$route.query.url);
        }
      })
      .catch((err) => {
        console.log(err);
        this.$router.push("/");
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    checkUser() {
      if (!this.$cookies.get("userData")) {
        // localStorage.removeItem("userData");
        this.$router.push("/login");
      }
    },
  },
};
</script>